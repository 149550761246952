<template>
  <LiefengContent>
    <template v-slot:title> 居民信息驳回中心 </template>
    <template v-slot:toolsbarLeft>
      <LiefengTree
        ref="mychild"
        :fixedmenu="true"
        @handleData="getList"
        style="width: 200px"
        treeID="mychild"
        fixedContentID="contentarea"
      ></LiefengTree>
    </template>
    <template v-slot:toolsbarRight>
      <Input
        :maxlength="20"
        enter-button
        v-model.trim="searchData.name"
        placeholder="姓名"
        style="width: 150px; margin-right: 10px"
        @on-enter="hadlePageSize"
      />
      <Input
        enter-button
        :maxlength="20"
        v-model.trim="searchData.phone"
        placeholder="手机号"
        style="width: 150px; margin-right: 10px"
        @on-enter="hadlePageSize"
      />
      <Button
        style="margin: 0 10px 0 0"
        type="primary"
        icon="ios-search"
        @click="getList"
        >查询</Button
      >
      <Button
        style="margin: 0 10px 0 0"
        type="success"
        icon="ios-refresh"
        @click="rest"
        >重置</Button
      >
      <Button
        @click="exportExcel"
        type="error"
        icon="ios-cloud-download-outline"
        >导出</Button
      >
      <Icon
        type="md-more"
        @click="selectQuery = true"
        style="font-size: 24px; line-height: 32px; cursor: pointer"
        title="更多查询条件"
      />

      <Drawer
        title="查询条件"
        v-model="selectQuery"
        width="320"
        :styles="{
          height: 'calc(100% - 55px)',
          overflow: 'auto',
          paddingBottom: '53px',
          position: 'static',
        }"
      >
        <Form :model="searchData" :label-colon="true">
          <FormItem label="姓名">
            <Input
              v-model.trim="searchData.name"
              placeholder="请输入姓名"
              :maxlength="20"
            ></Input>
          </FormItem>
          <FormItem label="手机号">
            <Input
              :maxlength="20"
              v-model.trim="searchData.phone"
              placeholder="请输入手机号"
            ></Input>
          </FormItem>
          <FormItem label="详细地址">
            <Input
              :maxlength="20"
              v-model.trim="searchData.detailAddr"
              placeholder="请输入详细地址"
              style="width: 100%"
            />
          </FormItem>
          <FormItem label="家庭联络人">
            <Select
              v-model="searchData.contact"
              placeholder="请选择是否家庭联络人"
              clearable
            >
              <Option value="1">是</Option>
              <Option value="2">否</Option>
            </Select>
          </FormItem>
        </Form>
        <div class="demo-drawer-footer">
          <Button style="margin-right: 8px" @click="selectQuery = false"
            >取消</Button
          >
          <Button type="primary" @click="hadlePageSize">查询</Button>
        </div>
      </Drawer>
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :loading="loading"
        :fixTable="true"
        :curPage="curPage"
        :pagesizeOpts="[20, 30, 50, 100]"
        :total="total"
        :page-size="pageSize"
        @hadlePageSize="hadlePageSize"
      ></LiefengTable>
    </template>
  </LiefengContent>
</template>

<script>
//@route("/peorejectindex")
import LiefengContent from "@/components/LiefengContent";
import LiefengTable from "@/components/LiefengTable";
import LiefengTree from "@/components/LiefengTree";
import LiefengModal from "@/components/LiefengModal";
export default {
  watch: {
    selectQuery(newV) {
      if (!newV) {
        this.searchData.phone = "";
        this.searchData.name = "";
        this.searchData.detailAddr = "";
        this.searchData.contact = "";
      }
    },
  },
  data() {
    return {
      selectQuery: false,
      loading: false,
      //菜单树编码和名称
      orgCode: "",
      orgName: "",
      searchData: {
        name: "",
        phone: "",
        pageSize: 20,
        page: 1,
      },
      status: 3, //审核状态 1：未审核 2：已通过 3 驳回
      treeParams: {
        status: 3, //必填
        roadCode: "",
        projectCode: "",
        communityCode: "", //必填
        pageSize: 20,
        page: 1,
        name: "",
        phone: "",
        oemCode: "", //必填  缓存拿
      },
      treeData: [],
      talbeColumns: [
        {
          title: "姓名",
          key: "name",
          width: 80,
          align: "center",
        },
        {
          title: "性别",
          key: "sexLabel",
          width: 80,
          align: "center",
        },
        {
          title: "联系电话",
          key: "phone",
          width: 130,
          align: "center",
        },
        {
          title: "关系",
          key: "inviterRelationshipLabel",
          align: "center",
        },

        // {
        //     title: "社区",
        //     key: "community",
        // },

        {
          title: "街巷",
          key: "roadName",
          align: "center",
        },
        // {
        //     title: "楼栋",
        //     key: "projectName",
        // },
        // {
        //     title: "房号",
        //     key: "houseNum",
        // },
        {
          title: "所在详细地址",
          key: "detailAddr",
          align: "center",
          render: (h, params) => {
            return h("div", [h("span", {}, params.row.detailAddr)]);
          },
        },
        {
          title: "是否家庭户代表",
          key: "contactLabel",
          align: "center",
        },
        {
          title: "驳回原因",
          key: "reason",
          align: "center",
        },
        // {
        //   title: "操作",
        //   slot: "action",
        //   width: 80,
        // },
        // {
        //     title: "填写人",
        //     key: "fillerName",
        // },
        // {
        //     title: "操作",
        //     slot: "action",
        //     width: 80,
        //     align: "center",
        //     render: (h, params) => {
        //         return h("div", [
        //             h(
        //                 "Button", {
        //                     props: {
        //                         type: "error",
        //                         size: "small",
        //                     },
        //                     on: {
        //                         click: () => {
        //                             this.remove(params.row);
        //                         },
        //                     },
        //                 },
        //                 "删除"
        //             ),
        //         ]);
        //     },
        // },
      ],
      tableData: [],
      pageSize: 20,
      curPage: 1,
      total: 0,
    };
  },
  methods: {
    //导出excel
    exportExcel() {
      this.$Modal.confirm({
        title: "温馨提示",
        content:
          "<p>您正在导出当前页的数据，是否继续？（若要导出更多数据，请设置每页条数。）</p>",
        onOk: () => {
          let tHeader = [
            "姓名",
            "性别",
            "联系电话",
            "关系",
            "街巷",
            "所在详细地址",
            "是否家庭户代表",
            "驳回原因",
          ];
          let filterVal = [
            "name",
            "sexLabel",
            "phone",
            "inviterRelationshipLabel",
            "roadName",
            "detailAddr",
            "contactLabel",
            "reason",
          ];
          this.$core.exportExcel(
            tHeader,
            filterVal,
            this.tableData,
            "居民信息驳回列表"
          );
        },
      });
    },
    //重置
    rest() {
      this.searchData.phone = "";
      this.searchData.name = "";
      this.searchData.detailAddr = "";
      this.searchData.contact = "";
      this.curPage = 1;
      this.getList();
    },
    //page页面改变
    hadlePageSize(obj) {
      this.getList(obj);
    },
    //菜单树
    getList(data) {
      this.loading = true;
      if (data) {
        //第一次进来页面就会执行
        if (data.communityCode) {
          this.searchData.name = "";
          this.searchData.phone = "";
          this.orgCode = data.communityCode;
          this.orgName = data.orgName;
          this.tableData = [];
          this.treeParams = {
            ...data,
            status: this.status, //必填
            name: this.searchData.name,
            phone: this.searchData.phone,
            oemCode: parent.vue.loginInfo.userinfo.oemCode,
            page: data.page,
            pageSize: data.pageSize,
          };
          this.curPage = data.page;
          this.pageSize = data.pageSize;
        }
        if (data.page) {
          //切换页面时
          this.curPage = data.page;
          this.pageSize = data.pageSize;
          this.treeParams.page = data.page;
          this.treeParams.pageSize = data.pageSize;
          this.treeParams.name = this.searchData.name;
          this.treeParams.phone = this.searchData.phone;
          this.treeParams.detailAddr = this.searchData.detailAddr;
          this.treeParams.contact = this.searchData.contact;
        }
        if (data.cancelable) {
          //点击查询时  查询返回第一页的数据
          this.treeParams.page = 1;
          this.treeParams.inviterFlag = 2;

          this.treeParams.name = this.searchData.name;
          this.treeParams.phone = this.searchData.phone;
          this.treeParams.detailAddr = this.searchData.detailAddr;
          this.treeParams.contact = this.searchData.contact;
          this.curPage = 1;
        }
      } else {
        //重置的时候执行
        this.treeParams = {
          communityCode: this.treeParams.communityCode,
          roadCode: this.treeParams.roadCode,
          status: 1, //必填
          name: this.searchData.name,
          phone: this.searchData.phone,
          detailAddr: this.searchData.detailAddr,
          contact: this.searchData.contact,
          oemCode: parent.vue.loginInfo.userinfo.oemCode,
          page: this.curPage,
          pageSize: this.pageSize,
          inviterFlag: 2, //1 居民 2 成员
        };
      }
      //请求页面表格数据
      this.$get(
        "/syaa/pc/sy/user/residents/selectSyFamilyMemberPage",
        this.treeParams
      )
        .then((res) => {
          if (res.code === "200") {
            this.tableData = res.dataList;
            this.total = res.maxCount;
            this.curPage = res.currentPage;
            this.selectQuery = false;
            this.loading = false;
          } else {
            this.$message.error({
              background: true,
              content: "获取数据失败！",
            });
            this.loading = false;
          }
        })
        .catch((err) => {
          this.$message.error({
            background: true,
            content: "获取数据失败！",
          });
          this.loading = false;
        });
    },
  },
  components: {
    LiefengTable,
    LiefengTree,
    LiefengContent,
    LiefengModal,
  },
};
</script>
    
<style scoped lang='less'>
/deep/.ivu-drawer-body {
  .ivu-form-item {
    width: 100%;
    margin-bottom: 0;
  }
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
</style>